import React, { PureComponent, Fragment } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { string, bool } from 'prop-types';
import { shouldShowErrors } from '@widergy/web-utils/lib/form';
import { UTLabel } from '@widergy/energy-ui';

import { inputPropTypes, metaPropTypes } from 'types/formTypes';

class UTCheckbox extends PureComponent {
  handleChange = (event, value) => this.props.input.onChange(value);

  render() {
    return (
      <Fragment>
        <FormControlLabel
          value={this.props.input.value}
          label={<UTLabel>{this.props.label}</UTLabel>}
          control={<Checkbox />}
          onChange={this.handleChange}
          checked={this.props.controlledCheck}
        />
        {shouldShowErrors(this.props.meta) && this.props.showError && (
          <UTLabel error small>
            {this.props.meta.error}
          </UTLabel>
        )}
      </Fragment>
    );
  }
}

UTCheckbox.propTypes = {
  input: inputPropTypes,
  meta: metaPropTypes,
  label: string.isRequired,
  showError: bool,
  controlledCheck: bool
};

export default UTCheckbox;
