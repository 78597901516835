import React from 'react';
import i18 from 'i18next';
import { bool, func } from 'prop-types';
import { reduxForm } from 'redux-form';
import { handleKeyDown } from '@widergy/utilitygo-forms-web';
import { UTButton } from '@widergy/energy-ui';

import { referenceType } from 'types/officeTypes';
import { CREATE_TURNS_USER_INFO } from 'constants/reduxForms';
import UTForm from 'app/components/UTForm';
import GridWraper from 'app/screens/Dashboard/screens/Turns/components/GridWrapper';

import { fields } from './constants';
import styles from './styles.module.scss';

const ContactInformation = ({
  disabled,
  disableForm,
  enableForm,
  handleSubmit,
  hasInitialValues,
  invalid,
  loading,
  reference,
  submitFailed,
  reset
}) => {
  const handleCancelEdition = () => {
    reset();
    disableForm();
  };

  return (
    <GridWraper
      reference={reference}
      title={i18.t('Turns:contactInformationTitle')}
      className={styles.gridWrapper}
      titleClassName={styles.gridTitle}
    >
      {hasInitialValues && (
        <UTButton onPress={disabled ? enableForm : handleCancelEdition} text className={styles.editButton}>
          {i18.t(`Turns:${disabled ? 'editContactInformation' : 'cancelEditionContactInformation'}`)}
        </UTButton>
      )}
      <form onSubmit={handleSubmit} onKeyDown={handleKeyDown(handleSubmit)} className={styles.content}>
        <UTForm
          fields={fields}
          disableAllFields={disabled}
          formClasses={{ fieldRowContainer: styles.fieldRowContainer }}
        />
        {!disabled && (
          <div className={styles.buttonContainer}>
            <UTButton
              disabled={submitFailed && invalid}
              loading={loading}
              className={styles.submitButton}
              type="submit"
            >
              {i18.t(
                `Turns:${hasInitialValues ? 'contactInformationEditionSubmit' : 'contactInformationSubmit'}`
              )}
            </UTButton>
          </div>
        )}
      </form>
    </GridWraper>
  );
};

ContactInformation.propTypes = {
  disabled: bool,
  disableForm: func,
  enableForm: func,
  hasInitialValues: bool,
  handleSubmit: func,
  invalid: bool,
  loading: bool,
  reference: referenceType,
  submitFailed: bool,
  reset: func
};

export default reduxForm({ form: CREATE_TURNS_USER_INFO, enableReinitialize: true })(ContactInformation);
