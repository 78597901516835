import { AlertHandler } from '@widergy/energy-ui';
import i18n from 'i18next';

import { MANAGE_TURNS } from 'constants/routes';

export default {
  alertDialog: {
    dialogProps: {
      whiteTitle: true
    },
    textProps: {
      white: true
    }
  },
  dialogButton: {
    accept: {
      text: true
    },
    cancel: {
      text: true,
      gray: true
    }
  },
  dialogLabel: {
    primary: true
  },
  topbar: {
    link: '',
    withLogo: true,
    withLogout: true,
    withLabel: false
  },
  footer: {
    enabled: false
  },
  turns: {
    createTurnsEnabled: false
  },
  forms: {
    clientNumberLength: 9,
    nameMaxLength: 20,
    lastNameMaxLength: 20
  },
  buttonRadius: '0px',
  fontFamily: 'Helvetica',
  defaultRoute: MANAGE_TURNS,
  contactInformation: {
    emailRequired: true
  },
  applicationRefresh: {
    url: `${window.origin}/static/js/`,
    forceReload: true,
    withAlertAction: true,
    alertDispatcher: () => AlertHandler.error(i18n.t('Commons:refreshing')),
    pollingTime: 3600000
  }
};
