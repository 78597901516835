import i18 from 'i18next';
import {
  UTTextInput,
  UTLabel,
  UTImageRadio,
  UTPhoneInput,
  UTMap,
  UTCuit,
  UTAttachment,
  UTSelect,
  UTSwitch
} from '@widergy/energy-ui';
import * as FORMS from '@widergy/utilitygo-forms-web/dist/constants/fieldTypes';

import UTCheckbox from 'app/components/UTCheckbox';

export const DefaultMessages = {
  required: i18.t('Validations:required'),
  invalidEmail: i18.t('Validations:invalidEmail'),
  invalidFormat: i18.t('Validations:invalidFormat')
};
export const Components = {
  [FORMS.FIELD_TEXT]: UTTextInput,
  [FORMS.FIELD_ATTACHMENT]: UTAttachment,
  [FORMS.FIELD_LABEL]: UTLabel,
  [FORMS.FIELD_EMAIL]: UTTextInput,
  [FORMS.FIELD_CUIT]: UTCuit,
  [FORMS.FIELD_PHONE]: UTPhoneInput,
  [FORMS.FIELD_PASSWORD]: UTTextInput,
  [FORMS.FIELD_IMAGE_RADIO]: UTImageRadio,
  [FORMS.FIELD_IMAGE]: UTAttachment,
  [FORMS.FIELD_PDF]: UTAttachment,
  [FORMS.FIELD_MAP]: UTMap,
  [FORMS.FIELD_CREDIT_CARD]: UTTextInput,
  [FORMS.FIELD_CHECKBOX]: UTCheckbox,
  [FORMS.FIELD_SELECT]: UTSelect,
  [FORMS.FIELD_SWITCH]: UTSwitch
};
