import React, { PureComponent, Fragment } from 'react';
import { func } from 'prop-types';
import Menu from '@material-ui/core/Menu';
import FilterList from '@material-ui/icons/FilterList';
import { UTButton } from '@widergy/energy-ui';

import UTCheckbox from 'app/components/UTCheckbox';

import styles from './styles.module.scss';
import { filterOptions } from './constants';

class TurnsFilter extends PureComponent {
  state = { anchorEl: null, selectedOption: null };

  handleClick = event => this.setState({ anchorEl: event.currentTarget });

  handleClose = () => this.setState({ anchorEl: null });

  handleSelectOption = value => () => {
    this.setState(
      prevState => ({ selectedOption: prevState.selectedOption !== value && value }),
      () => this.props.onSelectFilter(this.state.selectedOption)
    );
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <Fragment>
        <UTButton
          aria-label="More"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onPress={this.handleClick}
          className={styles.filterButton}
        >
          <FilterList />
        </UTButton>
        <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          <div className={styles.filterOptions}>
            {filterOptions.map(option => (
              <UTCheckbox
                label={option.label}
                controlledCheck={this.state.selectedOption === option.value}
                input={{ onChange: this.handleSelectOption(option.value) }}
                meta={{}}
              />
            ))}
          </div>
        </Menu>
      </Fragment>
    );
  }
}

TurnsFilter.propTypes = {
  onSelectFilter: func
};

export default TurnsFilter;
