import i18 from 'i18next';

import edenor from 'config/utilities/edenor/texts';
import edelap from 'config/utilities/edelap/texts';
import eden from 'config/utilities/eden/texts';
import edes from 'config/utilities/edes/texts';
import edesa from 'config/utilities/edesa/texts';

const texts = {
  edenor,
  edelap,
  eden,
  edes,
  edesa
};

i18.init({
  lng: 'es',
  initImmediate: false,
  resources: texts[process.env.REACT_APP_UTILITY_NAME]
});
