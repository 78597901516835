import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import i18n from 'i18next';
import { func, string } from 'prop-types';

import { referenceType, valuesType } from 'types/officeTypes';
import OfficeActions from 'redux/office/actions';
import Offices from 'app/screens/Dashboard/screens/Turns/components/Offices';
import { clientNumberField } from 'constants/fields';

import { handleScrollTo } from '../../utils';

import styles from './styles.module.scss';

const SelectOffice = ({ onChange, reference, values, procedure }) => {
  const dispatch = useDispatch();
  const clientNumber = values.contactInformation[clientNumberField.key];

  useEffect(() => {
    handleScrollTo(reference);
    if (values.selectedOffice) onChange(null);
    dispatch(OfficeActions.getOffices(clientNumber));
  }, [values.contactInformation]);

  return (
    <Offices
      selectedOffice={values.selectedOffice}
      changeOffice={onChange}
      title={i18n.t('Turns:selectOfficeTitle')}
      className={styles.container}
      titleClassName={styles.gridTitle}
      gridSizeMD={3}
      gridSizeXS={12}
      reference={reference}
      procedure={procedure}
    />
  );
};

SelectOffice.propTypes = {
  onChange: func,
  reference: referenceType,
  values: valuesType,
  procedure: string
};

export default SelectOffice;
