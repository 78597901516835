export default {
  es: {
    Commons: {
      adherence: 'Adherir',
      accept: 'Aceptar',
      agreed: 'Entendido',
      cancel: 'Cancelar',
      email: 'Email',
      search: 'Buscar',
      back: 'Volver',
      account: 'Cuenta',
      history: 'Historial',
      add: 'Adherir',
      modify: 'Modificar',
      delete: 'Eliminar',
      password: 'Contraseña',
      signOut: 'Cerrar sesión',
      topbar: 'Backoffice',
      close: 'Cerrar',
      refreshing: 'Usted cuenta con una versión desactualizada. La página se refrescará en breve'
    },
    Alerts: {
      defaultTitle: 'Información',
      createNewClaim: 'Generar reclamo',
      claimTitle: '¡Gracias!',
      procedureTitle: '¡Listo!',
      errorTitle: 'Hubo un error',
      paymentTitle: '¡Gracias por tu pago!',
      rechargeTitle: '¡La recarga fue exitosa!',
      defaultErrorTitle: 'Hubo un error'
    },
    DefaultMessages: {
      signInFailure: 'No se pudo iniciar sesión, el servicio no está disponible en este momento',
      getUserInformationFailure:
        'Hubo un error al recuperar los datos del usuario, por favor intente nuevamente más tarde',
      getTurnsFailure: 'Hubo un error al recuperar los turnos',
      createTurnSuccess: 'El turno fue creado con exito.',
      createTurnFailure: 'Hubo un error al crear el turno. Probá intentando de nuevo más tarde.',
      confirmTurnSuccess: 'El turno fue confirmado correctamente',
      confirmTurnFailure: 'Hubo un error al confirmar el turno',
      cancelTurnSuccess: 'El turno fue cancelado correctamente',
      cancelTurnFailure: 'Hubo un error al cancelar el turno',
      getOfficesFailure: 'Hubo un error al recuperar las oficinas',
      getOfficeWorkingDaysFailure: 'Hubo un error al recuperar los dias de atencion de la oficina',
      getOfficeWorkingDaySlotsFailure: 'Hubo un error al recuperar los horarios de atencion',
      getRechargeHistoryFailure: 'Hubo un error al recuperar el historial de recargas',
      emergencyRechargeFailure: 'Hubo un error al realizar su recarga SOS'
    },
    Validations: {
      required: 'Campo obligatorio',
      passwordRequired: 'La contraseña es obligatoria',
      invalidFormat: 'Formato inválido',
      invalidEmail: 'El email ingresado no es válido',
      emailRequired: 'El email es obligatorio',
      emailConfirmationFailure: 'Los emails ingresados no coinciden.',
      sameEmail: 'El email debe ser distinto al actual.',
      clientNumberLengthValidation: 'El NIS debe ser de {{count}} caracteres',
      nameMaxLength: 'El nombre tiene que tener menos de 20 caracteres',
      lastNameMaxLength: 'El apellido debe tener menos de 20 caracteres'
    },
    Maintenance: {
      maintenanceMessage:
        'Estamos realizando tareas de mantenimiento, por favor ingresá nuevamente más tarde',
      networkError:
        'Detectamos un problema en la conexión con el servidor. Por favor, intentá nuevamente más tarde.'
    },
    Forms: {
      emailConfirmation: 'Confirmación de email',
      signInButton: 'Iniciar Sesión',
      signInTitle: 'Ingresá al backoffice',
      documentTypePlaceholder: 'Tipo de documento',
      documentNumberPlaceholder: 'Número de documento',
      documentNumberTitle: 'Número de documento',
      clientNumberTitle: 'Número de suministro',
      nameTitle: 'Nombre',
      lastNameTitle: 'Apellido',
      contactSource: 'Canal',
      phoneTitle: 'Teléfono',
      dni: 'DNI',
      cuit: 'CUIT',
      cip: 'CI provincial',
      cdi: 'Cédula de identidad',
      lc: 'Libreta cívica',
      le: 'Libreta de enrolamiento',
      cdt: 'Certificado de documento en trámite',
      other: 'Otros',
      passport: 'Pasaporte'
    },
    Turns: {
      tabManageTurns: 'Gestión de turnos',
      tabCreateTurns: 'Alta de turnos',
      title: 'Gestión de turnos',
      officesTitle: 'Seleccioná la oficina del turno generado',
      calendarTitle: 'Seleccioná la fecha',
      attentionHoursTitle: 'Seleccioná el horario',
      turnsListTitle: 'Confirmá o cancelá turnos',
      searchButton: 'Buscar',
      searchTurnsTitle: 'Buscar turnos por',
      statuscanceled: 'Estado: Cancelado',
      statuspending: 'Estado: Pendiente',
      statusconfirmed: 'Estado: Confirmado',
      statusabsent: 'Estado: Ausente',
      statusnot_attended: 'Estado: Sin atención',
      titleErrorCard: 'Ups! Hubo un problema',
      buttonErrorCard: 'Recargar Formulario',
      reloadCalendar: 'Recargar calendario',
      turnsByDayZeroResults: 'No se encontraron turnos en la fecha seleccionada',
      turnsByDocumentZeroResults: 'No hay turnos pendientes para el documento ingresado',
      office: 'Oficina: ',
      date: 'Fecha y hora: ',
      pendingTurn: 'Turno pendiente',
      cancelledTurn: 'Turno cancelado',
      confirmedTurn: 'Turno confirmado',
      searchByDNI: 'Buscar por DNI',
      filterBy: 'Filtrar por',
      confirmDialogTitle: 'Confirmación de turno',
      cancelDialogTitle: 'Cancelación de turno',
      confirmDialogContent: '¿Está seguro de confirmar el turno?',
      cancelDialogContent: '¿Está seguro de cancelar el turno?',
      cancelDialogReason: '¿Cuál es el motivo de la cancelación?',
      emptyOffices: 'No cuenta con oficinas por el momento',
      userInformation: 'Info del cliente',
      phone: 'Tel:',
      clientNumber: 'NIS:',
      email: 'Email:',
      notSpecified: 'No especificado',
      searchOfficesPlaceholder: 'Buscar oficina comercial',
      searchOfficesZeroResults: 'No se encontraron oficinas',
      selectProcedureTitle: 'Seleccioná el trámite a realizar',
      selectProcedurePlaceHolder: 'Seleccionar trámite',
      contactInformationTitle: 'Datos de contacto',
      contactInformationSubmit: 'Siguiente',
      contactInformationEditionSubmit: 'Aplicar cambios',
      selectOfficeTitle: 'Seleccioná la oficina',
      selectDateTitle: 'Seleccioná la fecha',
      selectTimeTitle: 'Seleccioná el horario',
      summaryTitle: 'Confirmá el turno',
      summaryDetailsTitle: 'Detalles del turno seleccionado',
      summaryDetailsSubtitle: 'Presioná la opción que desea modificar.',
      summaryUserDetails: 'Datos del cliente',
      summaryDetails: 'Datos del turno',
      summaryDetailName: 'Nombre:',
      summaryDetailNis: 'NIS:',
      summaryDetailPhone: 'Teléfono:',
      summaryDetailEmail: 'Email:',
      summaryDetailProcedure: 'Trámite:',
      editContactInformation: 'Editar campos',
      cancelEditionContactInformation: 'Cancelar',
      calendarReferenceNotAvailable: 'No disponible',
      calendarReferenceAvailable: 'Disponible',
      calendarReferenceSelected: 'Seleccionado',
      calendarReferenceTitle: 'Referencias:',
      calendarReferenceReminder: 'Recordá que no se puede pedir turno para el mismo día.',
      calendarReferenceNotice: 'El calendario habilitará sólo los días con turnos disponibles.',
      withoutTurns: 'No hay turnos disponibles',
      confirmTurnButton: 'Confirmar turno'
    }
  }
};
