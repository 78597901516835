import React, { useEffect, useState, Fragment, useMemo } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import { instanceOf, arrayOf, string, shape, func, bool } from 'prop-types';
import { isEmpty } from '@widergy/web-utils/lib/array';
import UTLabel from '@widergy/energy-ui/dist/components/UTLabel';

import { officesType } from 'types/officeTypes';
import OfficeActions from 'redux/office/actions';
import appConfig from 'config/appConfig';
import UTDialog from 'app/components/UTDialog';
import { TURN_STATUS } from 'constants/turns';

import { initialMonth, initialYear, getInitialDay } from './utils';
import ManageTurns from './layout';
import styles from './styles.module.scss';
import { CONFIRM, CANCEL } from './constants';
import CancelReasonSelector from './components/CancelReasonSelector';

const ManageTurnsContainer = ({
  dispatch,
  officeWorkingDays,
  officeWorkingDaysError,
  officeWorkingDaysLoading,
  offices,
  officesError,
  officeLoading,
  turnForTransition,
  turnActionLoading,
  cancelReasons
}) => {
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentDay, setCurrentDay] = useState(null);
  const [searchDialogIsOpen, setSearchDialogOpen] = useState(false);
  const [filterValue, setFilterValue] = useState();
  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [turnAction, setTurnAction] = useState({});

  const multipleCancelStatus = !isEmpty(cancelReasons);

  const isCancelAction = useMemo(() => turnForTransition.action === CANCEL, [turnForTransition]);

  const changeOffice = officeId => {
    const newDate = new Date();
    setCurrentDay(newDate);
    setCurrentDate(newDate);
    setSelectedOffice(officeId);
    dispatch(OfficeActions.getOfficeWorkingDays(officeId, newDate.getMonth() + 1, newDate.getFullYear()));
    dispatch(OfficeActions.cleanTurnsByDay());
  };

  const resetCalendar = () => {
    setCurrentDate(new Date());
    dispatch(OfficeActions.getOfficeWorkingDays(selectedOffice, initialMonth(), initialYear()));
  };

  const handleSetDay = date => {
    setCurrentDay(date);
    const daySelected = officeWorkingDays && officeWorkingDays.find(item => item.day === date.getDate());
    if (!daySelected) return;
    dispatch(OfficeActions.getTurnsByDay(selectedOffice, daySelected.id));
  };

  const handleChangeMonth = date => {
    setCurrentDate(date);
    dispatch(OfficeActions.getOfficeWorkingDays(selectedOffice, date.getMonth() + 1, date.getFullYear()));
    dispatch(OfficeActions.cleanTurnsByDay());
  };

  const handleCalendarChange = date => {
    if (!currentDay || date.getMonth() === currentDay.getMonth()) handleSetDay(date);
  };

  const handleSearchTurnsSubmit = values => {
    setSearchDialogOpen(true);
    dispatch(OfficeActions.getTurnsByDocument(values.document_number));
  };

  const handleCloseSearchDialog = () => {
    dispatch(OfficeActions.cleanTurnsByDocument());
    setSearchDialogOpen(false);
  };

  const handleCleanTurnForTransition = () => {
    dispatch(OfficeActions.setTurnForTransition(null));
  };

  const handleConfirmTurnAction = async () => {
    if (turnForTransition.action === CONFIRM)
      dispatch(OfficeActions.updateTurnStatus(turnForTransition.turnId, TURN_STATUS.CONFIRMED));
    else
      dispatch(
        OfficeActions.updateTurnStatus(
          turnForTransition.turnId,
          TURN_STATUS.CANCELED,
          multipleCancelStatus ? turnAction.action : undefined
        )
      );
    if (turnForTransition.afterAction) turnForTransition.afterAction();
    setActionDialogOpen(false);
  };

  useEffect(() => {
    dispatch(OfficeActions.getOffices());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(offices)) changeOffice(offices[0].id);
  }, [offices]);

  useEffect(() => {
    if (!officeWorkingDaysLoading && !officeWorkingDaysError)
      handleSetDay(getInitialDay(officeWorkingDays, currentDate));
  }, [officeWorkingDays]);

  useEffect(() => {
    if (turnForTransition.turnId) {
      setActionDialogOpen(true);
      setTurnAction({ action: turnForTransition.action });
    }
  }, [turnForTransition]);

  useEffect(() => {
    if (officeWorkingDaysError) {
      setCurrentDay(null);
      dispatch(OfficeActions.cleanTurnsByDay());
    }
  }, [officeWorkingDaysError]);

  const disableAcceptButtonOnCancelAction =
    isCancelAction && multipleCancelStatus && !turnAction.fromSelector;

  return (
    <Fragment>
      <ManageTurns
        selectedOffice={selectedOffice}
        changeOffice={changeOffice}
        currentDay={currentDay}
        handleCalendarChange={handleCalendarChange}
        handleChangeMonth={handleChangeMonth}
        onSearchTurnsSubmit={handleSearchTurnsSubmit}
        searchDialogIsOpen={searchDialogIsOpen}
        onCloseSearchDialog={handleCloseSearchDialog}
        resetCalendar={resetCalendar}
        hideCalendar={officesError}
        hideTurns={officesError || officeWorkingDaysError}
        onSelectFilter={setFilterValue}
        filterValue={filterValue}
        turnForTransition={turnForTransition}
        loading={officeLoading}
      />
      <UTDialog
        withCloseButton
        elevation={appConfig.dialogElevation}
        title={i18n.t(`Turns:${turnForTransition.action}DialogTitle`)}
        isOpen={actionDialogOpen}
        acceptButton={{
          onPress: handleConfirmTurnAction,
          disabled: turnActionLoading || disableAcceptButtonOnCancelAction,
          loading: turnActionLoading,
          className: styles.acceptButton,
          text: i18n.t('Commons:accept'),
          config: {
            ...appConfig.dialogButton.accept,
            uppercase: true,
            classes: { loading: styles.acceptButtonLoading }
          }
        }}
        cancelButton={{
          onPress: () => setActionDialogOpen(false),
          disabled: turnActionLoading,
          text: i18n.t('Commons:cancel'),
          className: styles.cancelButton,
          config: {
            ...appConfig.dialogButton.cancel,
            uppercase: true
          }
        }}
        onRequestClose={() => setActionDialogOpen(false)}
        TransitionProps={{ onExited: handleCleanTurnForTransition }}
        alertConfig={appConfig.dialogLabel}
        classNames={{
          container: styles.dialogContent
        }}
      >
        <Fragment>
          <UTLabel className={styles.turnActionContent}>
            {multipleCancelStatus && turnForTransition.action === CANCEL
              ? i18n.t(`Turns:cancelDialogReason`)
              : i18n.t(`Turns:${turnForTransition.action}DialogContent`)}
          </UTLabel>
          {multipleCancelStatus && isCancelAction && (
            <CancelReasonSelector getSelectedOption={setTurnAction} cancelReasons={cancelReasons} />
          )}
        </Fragment>
      </UTDialog>
    </Fragment>
  );
};

ManageTurnsContainer.propTypes = {
  officeWorkingDays: arrayOf(instanceOf(Date)),
  officeWorkingDaysError: string,
  officeWorkingDaysLoading: bool,
  offices: officesType,
  officesError: string,
  officeLoading: bool,
  turnForTransition: shape({ turnId: string, action: string, afterAction: func }),
  turnActionLoading: bool,
  cancelReasons: arrayOf(shape({ key: string, label: string }))
};

const mapStateToProps = store => ({
  offices: store.office.offices,
  officesError: store.office.officesError,
  officeLoading: store.office.officesLoading,
  officeWorkingDays: store.office.officeWorkingDays,
  officeWorkingDaysError: store.office.officeWorkingDaysError,
  officeWorkingDaysLoading: store.office.officeWorkingDaysLoading,
  turnForTransition: store.office.turnForTransition,
  turnActionLoading: store.office.confirmTurnLoading || store.office.cancelTurnLoading,
  cancelReasons: store.utility.configuration.turns_status_reasons
});

export default connect(mapStateToProps)(ManageTurnsContainer);
