import Immutable from 'seamless-immutable';
import { completeReducer, createReducer } from 'redux-recompose';

import { actions } from './actions';

export const defaultState = {
  offices: [],
  officeWorkingDays: null,
  turnsByDay: null,
  turnsByDocument: null,
  turnForTransition: {},
  officeWorkingDaySlots: null,
  officeWorkingDayError: null
};

const reducerDescription = {
  primaryActions: [
    actions.GET_OFFICES,
    actions.GET_OFFICE_WORKING_DAYS,
    actions.GET_OFFICE_WORKING_DAY_SLOTS,
    actions.GET_TURNS_BY_DAY,
    actions.GET_TURNS_BY_DOCUMENT,
    actions.CREATE_TURN,
    actions.UPDATE_TURN_STATUS
  ],
  override: {
    [actions.GET_OFFICE_WORKING_DAYS_FAILURE]: (state, action) =>
      Immutable.merge(state, {
        officeWorkingDaysLoading: false,
        officeWorkingDays: defaultState.officeWorkingDays,
        officeWorkingDaysError: action.payload
      }),
    [actions.CONFIRM_TURN]: state =>
      Immutable.merge(state, {
        confirmTurnLoading: true
      }),
    [actions.CANCEL_TURN]: state =>
      Immutable.merge(state, {
        cancelTurnLoading: true
      }),
    [actions.UPDATE_TURN_STATUS_SUCCESS]: (state, action) =>
      Immutable.merge(state, {
        updateTurnStatusLoading: false,
        turnsByDay:
          state.turnsByDay &&
          state.turnsByDay.map(turn =>
            turn.id === action.payload.turnId ? { ...turn, status: action.payload.status } : turn
          ),
        turnsByDocument:
          state.turnsByDocument &&
          state.turnsByDocument.map(turn => (turn.id === action.payload.id ? action.payload : turn))
      }),
    [actions.CLEAN_OFFICE_DATA]: state =>
      Immutable.merge(state, {
        offices: defaultState.offices,
        officeWorkingDays: defaultState.officeWorkingDays
      }),
    [actions.CLEAN_TURNS_BY_DAY]: state =>
      Immutable.merge(state, {
        turnsByDay: defaultState.turnsByDay,
        turnsByDayError: null
      }),
    [actions.CLEAN_TURNS_BY_DOCUMENT]: state =>
      Immutable.merge(state, {
        turnsByDocument: defaultState.turnsByDocument,
        turnsByDocumentError: null
      }),
    [actions.CLEAN_OFFICE_SLOTS]: state =>
      Immutable.merge(state, {
        officeWorkingDaySlots: defaultState.officeWorkingDaySlots,
        officeWorkingDayError: defaultState.officeWorkingDayError
      }),
    [actions.SET_TURN_FOR_TRANSITION]: (state, action) =>
      Immutable.merge(state, { turnForTransition: action.payload })
  }
};

export const reducer = createReducer(Immutable(defaultState), completeReducer(reducerDescription));
