export default {
  es: {
    Commons: {
      adherence: 'Adherir',
      accept: 'Aceptar',
      agreed: 'Entendido',
      cancel: 'Cancelar',
      email: 'Email',
      search: 'Buscar',
      back: 'Volver',
      account: 'Cuenta',
      history: 'Historial',
      add: 'Adherir',
      modify: 'Modificar',
      delete: 'Eliminar',
      signOut: 'Cerrar sesión',
      password: 'Contraseña',
      topbar: 'Backoffice',
      refreshing: 'Usted cuenta con una versión desactualizada. La página se refrescará en breve'
    },
    Alerts: {
      defaultTitle: 'Información',
      createNewClaim: 'Generar reclamo',
      claimTitle: '¡Gracias!',
      procedureTitle: '¡Listo!',
      errorTitle: 'Hubo un error',
      paymentTitle: '¡Gracias por tu pago!',
      rechargeTitle: '¡La recarga fue exitosa!',
      defaultErrorTitle: 'Hubo un error'
    },
    DefaultMessages: {
      getAccountEmailFailure:
        'Hubo un error al recuperar los datos de la cuenta, por favor intentá nuevamente más tarde.',
      addEmailSuccess: 'La cuenta se ha adherido a factura digital correctamente.',
      addEmailFailure: 'Hubo un error al adherir su cuenta.',
      editEmailSuccess: 'La cuenta fue actualizada correctamente.',
      editEmailFailure:
        'Hubo un error al actualizar la asociación a factura digital de la cuenta, por favor intente nuevamente más tarde.',
      removeEmailSuccess: 'La baja de factura digital fue realizada exitosamente.',
      removeEmailFailure:
        'Hubo un error al realizar la baja de factura digital de la cuenta, por favor intente nuevamente más tarde.',
      getHistoryAdherenceFailure:
        'Hubo un error al recuperar el historial de adhesiones a factura digital, por favor intente nuevamente más tarde.',
      getHistorySendBillsFailure:
        'Hubo un error al recuperar el historial de envio de facturas, por favor intente nuevamente más tarde.',
      signInFailure: 'No se pudo iniciar sesión, el servicio no está disponible en este momento',
      getUserInformationFailure:
        'Hubo un error al recuperar los datos del usuario, por favor intente nuevamente más tarde',
      getPrepaidMeterFailure:
        'Hubo un error al recuperar los datos de la cuenta, por favor intentá nuevamente más tarde.',
      getRechargeHistoryFailure: 'Hubo un error al recuperar el historial de recargas',
      emergencyRechargeFailure: 'Hubo un error al realizar su recarga SOS'
    },
    AccountSearch: {
      title: 'Adhesión a factura digital',
      label: 'Ingresá el número de cuenta a gestionar',
      fieldTitle: 'Número de cuenta',
      fieldLabel: 'Ingresá el número de cuenta de 10 dígitos'
    },
    PrepaidMeterSearch: {
      title: 'Recarga SOS',
      label: 'Ingresá el número de medidor a gestionar',
      fieldTitle: 'Número de medidor',
      fieldLabel: 'Ingresá el número de medidor de 11 dígitos'
    },
    RechargeInformation: {
      title: 'Detalles de tu carga:',
      clientNumber: 'Id Cuenta:',
      holderName: 'Titular:',
      address: 'Dirección:',
      nextDate: 'Próxima recarga a partir del:',
      energyAmount: 'Energía (kWh) a cargar:',
      energyAccumulated: 'Deuda acumulada (en kWh):',
      recharge: 'recargar'
    },
    Validations: {
      required: 'Campo obligatorio',
      passwordRequired: 'La contraseña es obligatoria',
      clientNumberLengthValidation: 'Debe ingresar 10 dígitos',
      prepaidMeterMinLength: 'Debe ingresar 11 dígitos',
      invalidFormat: 'Formato inválido',
      invalidEmail: 'El email ingresado no es válido',
      emailRequired: 'El email es obligatorio',
      emailConfirmationFailure: 'Los emails ingresados no coinciden.',
      sameEmail: 'El email debe ser distinto al actual.'
    },
    DigitalBill: {
      title: 'Email',
      withoutEmail: 'La cuenta no se encuentra adherida a factura digital.',
      addTitle: 'Adhesión a factura digital',
      editTitle: 'Modificar email',
      removeTitle: 'Cancelar adhesión',
      removeField:
        '¿Estás seguro de realizar la cancelación a la adhesión de factura digital para la cuenta: {{accountNumber}}? Una vez realizado, dejará de recibir en su correo eléctronico la factura.'
    },
    History: {
      sendBills: 'Envío de Facturas',
      adherence: 'Adhesiones',
      title: 'Historial',
      action: 'Accion',
      date: 'Fecha',
      channel: 'Canal de transacción',
      contactSource: 'Canal de contacto',
      pending: 'Pendiente de envío',
      send: 'Recibido',
      rejected: 'Error de email',
      add: 'Alta',
      modify: 'Modificación',
      delete: 'Baja',
      historyEmpty: 'Aún no hay datos para la cuenta',
      nis: 'Nº {{accountNumber}}',
      prevEmail: 'Email anterior',
      email: 'Email asignado',
      billId: 'N° de Factura',
      status: 'Estado',
      sendIntents: 'Intentos de envío',
      username: 'Usuario',
      open: 'Email abierto',
      spam: 'Marcado como spam',
      read: 'Leído'
    },
    Maintenance: {
      maintenanceMessage:
        'Estamos realizando tareas de mantenimiento, por favor ingresá nuevamente más tarde',
      networkError:
        'Detectamos un problema en la conexión con el servidor. Por favor, intentá nuevamente más tarde.'
    },
    Forms: {
      userLabel: 'Usuario',
      passwordLabel: 'Contraseña',
      emailConfirmation: 'Confirmación de email',
      signInButton: 'Iniciar Sesión',
      signInTitle: 'Ingresá al backoffice',
      contactSource: 'Canal de contacto'
    },
    ContactSources: {
      social_networks: 'Redes sociales',
      callcenter: 'Centro de atención telefónica',
      video_conference: 'Videopresencial ',
      personal: 'Personalmente',
      email: 'Correo electrónico',
      virtual_office: 'edenordigital',
      web_page: 'Página de internet',
      internal: 'Gestión Interna',
      telephonic: 'Telefónico'
    },
    Channels: {
      OFICINA_COMERCIAL: 'Oficina comercial',
      OFICINA_VIRTUAL: 'Oficina virtual',
      ED_VERIF_EMAIL: 'Verificación de email',
      PORTAL_GESTIONES: 'Portal de gestiones'
    },
    Home: {
      digitalBill: 'Factura digital',
      emergencyRecharge: 'Recarga SOS'
    },
    RechargeHistory: {
      infoClientNumber: 'N° de cuenta: {{clientNumber}}',
      infoPrepaidMeterNumber: 'N° de medidor: {{prepaidMeterNumber}}',
      paymentCode: 'Código de pago',
      paymentMethod: 'Medio de pago',
      amount: 'Monto',
      doneBy: 'Realizado por',
      token: 'Token',
      energyAmount: 'Energía (kWh))',
      seeTicket: 'Ver ticket',
      title: 'Historial de recargas'
    }
  }
};
