export const initialMonth = () => new Date().getMonth() + 1;

export const initialYear = () => new Date().getFullYear();

export const getInitialDay = (array, date) => {
  const today = new Date();
  if (date.getMonth() === today.getMonth()) return today;

  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const newDate = array.find(_date => _date.available);
  return new Date(`${year}/${month}/${newDate ? newDate.day : '1'}`);
};
