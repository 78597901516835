import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createSelector } from 'reselect';
import { arrayOf, bool, any, func, string } from 'prop-types';
import i18 from 'i18next';
import { UTLabel, UTButton } from '@widergy/energy-ui';
import { isEmpty } from '@widergy/web-utils/lib/array';
import Search from '@material-ui/icons/Search';

import UTForm from 'app/components/UTForm';
import UTDialog from 'app/components/UTDialog';
import { SEARCH_TURNS_FORM } from 'constants/reduxForms';
import GridWrapper from 'app/screens/Dashboard/screens/Turns/components/GridWrapper';
import appConfig from 'config/appConfig';

import CardError from '../../../../components/CardError';
import Turn from '../Turn';

import styles from './styles.module.scss';
import TurnsFilter from './components/TurnsFilter';
import { fields } from './constants';

const SearchTurns = ({
  turns,
  loading,
  handleSubmit,
  turnsError,
  invalid,
  searchDialogIsOpen,
  onCloseSearchDialog,
  onSelectFilter
}) => (
  <div>
    <div className={styles.searchHeader}>
      <UTLabel small gray uppercase>
        {i18.t('Turns:searchByDNI')}
      </UTLabel>
      <UTLabel small gray uppercase>
        {i18.t('Turns:filterBy')}
      </UTLabel>
    </div>
    <form className={styles.formContainer} onSubmit={handleSubmit}>
      <UTForm fields={fields} />
      <UTButton type="submit" disabled={invalid} uppercase className={styles.searchButton}>
        <Search />
      </UTButton>
      <TurnsFilter onSelectFilter={onSelectFilter} />
    </form>
    <UTDialog
      withCloseButton
      elevation={appConfig.dialogElevation}
      title=" "
      isOpen={searchDialogIsOpen}
      withClickAway={!loading}
      onRequestClose={onCloseSearchDialog}
      alertConfig={appConfig.dialogLabel}
    >
      <GridWrapper loading={loading} loadingClassName={styles.loading}>
        {turnsError ? (
          <div>
            <CardError error={turnsError} />
          </div>
        ) : turns && isEmpty(turns) ? (
          <UTLabel darkGray large semibold className={styles.label}>
            {i18.t('Turns:turnsByDocumentZeroResults')}
          </UTLabel>
        ) : (
          !isEmpty(turns) && (
            <div className={styles.turns}>
              {turns.map(turn => (
                <Turn
                  turnData={turn}
                  showOffice
                  className={styles.turn}
                  afterConfirm={onCloseSearchDialog}
                  afterCancel={onCloseSearchDialog}
                />
              ))}
            </div>
          )
        )}
      </GridWrapper>
    </UTDialog>
  </div>
);

SearchTurns.propTypes = {
  turns: arrayOf(any),
  loading: bool,
  turnsError: string,
  handleSubmit: func,
  searchDialogIsOpen: bool,
  onCloseSearchDialog: func,
  onSelectFilter: func,
  invalid: bool
};

export const turnsSelector = createSelector(
  store => store.office.turnsByDocument,
  turnsByDocument => turnsByDocument && turnsByDocument.filter(item => item.status === 'pending')
);

const mapStateToProps = store => ({
  turns: turnsSelector(store),
  loading: store.office.turnsByDocumentLoading,
  turnsError: store.office.turnsByDocumentError
});

export default connect(mapStateToProps)(reduxForm({ form: SEARCH_TURNS_FORM })(SearchTurns));
