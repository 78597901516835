import React, { memo } from 'react';
import { bool, func, number } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { UTTouchableWithoutFeedback, UTLabel } from '@widergy/energy-ui';

import { officeType } from 'types/officeTypes';

import { ReactComponent as Done } from './assets/done.svg';
import styles from './styles.module.scss';

const OfficeTag = ({ changeOffice, isSelected, office, gridSizeMD, gridSizeXS }) => (
  <Grid item xs={gridSizeXS} md={gridSizeMD} classes={{ root: styles.grid }}>
    <UTTouchableWithoutFeedback
      onClick={() => changeOffice(office.id)}
      className={`
        ${styles.office} ${isSelected ? styles.selected : styles.unselected}
      `}
    >
      <div className={styles.circle}>{isSelected && <Done className={styles.doneIcon} />}</div>
      <div>
        <UTLabel white={isSelected} gray={!isSelected} bold className={styles.officeName}>
          {office.name}
        </UTLabel>
        <UTLabel white={isSelected} gray={!isSelected}>
          {`${office.city}${office.city && office.address ? ' - ' : ''}${office.address}`}
        </UTLabel>
      </div>
    </UTTouchableWithoutFeedback>
  </Grid>
);

OfficeTag.propTypes = {
  office: officeType,
  changeOffice: func,
  isSelected: bool,
  gridSizeMD: number,
  gridSizeXS: number
};

export default memo(OfficeTag);
