import edenor from 'config/utilities/edenor/config';
import edelap from 'config/utilities/edelap/config';
import eden from 'config/utilities/eden/config';
import edes from 'config/utilities/edes/config';
import edesa from 'config/utilities/edesa/config';

const config = {
  edenor,
  edelap,
  eden,
  edes,
  edesa
};

export default config[process.env.REACT_APP_UTILITY_NAME];
