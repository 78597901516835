import { AlertHandler } from '@widergy/energy-ui';
import i18n from 'i18next';

import { DIGITAL_BILL } from 'constants/routes';

export default {
  alertDialog: {
    dialogProps: {
      whiteTitle: true
    },
    textProps: {
      white: true
    }
  },
  dialogButton: {
    accept: {
      text: true
    },
    cancel: {
      text: true,
      gray: true
    }
  },
  dialogLabel: {
    primary: true
  },
  topbar: {
    link: 'https://www.edenor.com/',
    withLogo: false,
    withLogout: false,
    withLabel: false
  },
  footer: {
    enabled: true
  },
  turns: {
    createTurnsEnabled: false
  },
  forms: {
    clientNumberLength: 10,
    nameMaxLength: 999,
    lastNameMaxLength: 999
  },
  fontFamily: 'Nunito',
  defaultRoute: DIGITAL_BILL,
  contactInformation: {
    emailRequired: true
  },
  applicationRefresh: {
    url: `${window.origin}/static/js/`,
    forceReload: true,
    withAlertAction: true,
    alertDispatcher: () => AlertHandler.error(i18n.t('Commons:refreshing')),
    pollingTime: 3600000
  }
};
