import React from 'react';
import { bool, func, string } from 'prop-types';
import { connect } from 'react-redux';
import { UTLabel } from '@widergy/energy-ui';
import { push } from 'connected-react-router';
import i18 from 'i18next';

import { ROUTES } from 'constants/routes';
import appConfig from 'config/appConfig';
import UTDialog from 'app/components/UTDialog';

import styles from './styles.module.scss';

const ResultDialog = ({ createTurnMessage, dispatch, handleClose, isOpen }) => {
  const closeDialog = () => {
    handleClose();
    dispatch(push(ROUTES.DASHBOARD));
  };

  return (
    <UTDialog
      withCloseButton
      isOpen={isOpen}
      onRequestClose={closeDialog}
      acceptButton={{
        onPress: closeDialog,
        text: i18.t('Commons:accept'),
        config: { ...appConfig.dialogButton.accept, uppercase: true }
      }}
    >
      <UTLabel xlarge bold className={styles.label}>
        {createTurnMessage}
      </UTLabel>
    </UTDialog>
  );
};

ResultDialog.propTypes = {
  createTurnMessage: string,
  handleClose: func,
  isOpen: bool
};

const mapStateToProps = store => ({
  createTurnMessage: store.office.createTurn
});

export default connect(mapStateToProps)(ResultDialog);
