import { UTILITIES } from 'config/common';
import {
  ROUTE_TYPES,
  HISTORY_ADHERENCE,
  HISTORY_SEND_BILL,
  DIGITAL_BILL,
  EMERGENCY_RECHARGE,
  MANAGE_TURNS,
  RECHARGE_HISTORY
} from 'constants/routes';

import History from './screens/AccountHistory';
import AccountAndPrepaid from './screens/AccountAndPrepaid';
import Turns from './screens/Turns';
import RechargeHistory from './screens/RechargeHistory';

const { PRIVATE } = ROUTE_TYPES;

export const routes = {
  [UTILITIES.EDENOR]: [
    {
      exact: true,
      type: PRIVATE,
      path: HISTORY_ADHERENCE,
      component: History
    },
    {
      exact: true,
      type: PRIVATE,
      path: HISTORY_SEND_BILL,
      component: History
    },
    {
      exact: true,
      type: PRIVATE,
      path: DIGITAL_BILL,
      component: AccountAndPrepaid
    },
    {
      exact: true,
      type: PRIVATE,
      path: EMERGENCY_RECHARGE,
      component: AccountAndPrepaid
    },
    {
      exact: true,
      type: PRIVATE,
      path: RECHARGE_HISTORY,
      component: RechargeHistory
    }
  ],
  [UTILITIES.EDELAP]: [
    {
      exact: false,
      type: PRIVATE,
      path: MANAGE_TURNS,
      component: Turns
    }
  ],
  [UTILITIES.EDEN]: [
    {
      exact: false,
      type: PRIVATE,
      path: MANAGE_TURNS,
      component: Turns
    }
  ],
  [UTILITIES.EDES]: [
    {
      exact: false,
      type: PRIVATE,
      path: MANAGE_TURNS,
      component: Turns
    }
  ],
  [UTILITIES.EDESA]: [
    {
      exact: false,
      type: PRIVATE,
      path: MANAGE_TURNS,
      component: Turns
    }
  ]
};
