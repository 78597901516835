import React, { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bool, string, func } from 'prop-types';
import i18 from 'i18next';
import moment from 'moment';
import { UTLabel, UTIconButton } from '@widergy/energy-ui';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

import OfficeActions from 'redux/office/actions';
import { turnType } from 'types/officeTypes';
import { TURN_STATUS } from 'constants/turns';

import { CONFIRM, CANCEL } from '../../constants';

import styles from './styles.module.scss';

const Turn = ({
  dispatch,
  turnData,
  className,
  showOffice,
  afterConfirm,
  afterCancel,
  turnForTransitionId
}) => {
  const [aceptDialogIsOpen, setAceptDialogIsOpen] = useState(false);
  const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const confirmTurn = () => {
    setAceptDialogIsOpen(true);
    dispatch(OfficeActions.setTurnForTransition(turnData.id, CONFIRM, afterConfirm));
  };

  const cancelTurn = () => {
    setCancelDialogIsOpen(true);
    dispatch(OfficeActions.setTurnForTransition(turnData.id, CANCEL, afterCancel));
  };

  useEffect(() => {
    if (!turnForTransitionId && (aceptDialogIsOpen || cancelDialogIsOpen)) {
      setAceptDialogIsOpen(false);
      setCancelDialogIsOpen(false);
    }
  }, [turnForTransitionId]);

  const handleInfo = () => setOpenInfo(!openInfo);

  return (
    <div className={`${styles.turn} ${className}`}>
      <div className={styles.containerTurn}>
        <div className={styles.turnText}>
          {turnData.procedure_name && (
            <UTLabel gray className={styles.generalLabel}>
              **{turnData.procedure_name}**
            </UTLabel>
          )}
          <UTLabel gray className={styles.generalLabel}>
            **{i18.t('Turns:date')}**&nbsp;
            {moment(turnData.date).format('DD/MM/YYYY')}&nbsp;
            {turnData.working_day_slot.begins_at} - {turnData.working_day_slot.ends_at}
          </UTLabel>
          {showOffice && (
            <UTLabel gray className={styles.generalLabel}>
              {i18.t('Turns:office')} **{turnData.office_city}** {turnData.office_address}
            </UTLabel>
          )}
          <UTLabel semibold className={styles[`${turnData.status}Turn`]}>
            {i18.t(`Turns:status${turnData.status}`)}
          </UTLabel>
        </div>
        {turnData.status === TURN_STATUS.PENDING && (
          <div className={styles.turnButtons}>
            <UTIconButton className={styles.iconButton} onClick={confirmTurn}>
              <DoneIcon className={aceptDialogIsOpen ? styles.selectedConfirmTurn : styles.confirmTurn} />
            </UTIconButton>
            <UTIconButton className={styles.iconButton} onClick={cancelTurn}>
              <CloseIcon className={cancelDialogIsOpen ? styles.selectedCancelTurn : styles.cancelTurn} />
            </UTIconButton>
          </div>
        )}
      </div>
      <div className={styles.divider} />
      <div className={styles.containerUserInfo}>
        <div className={styles.containerDocumentName}>
          {turnData.user_document_type && turnData.user_document_number && (
            <UTLabel gray className={styles.documentLabel}>
              {`**${turnData.user_document_type.toUpperCase()}:** ${turnData.user_document_number}`}
            </UTLabel>
          )}
          {turnData.user_first_name && turnData.user_last_name && (
            <UTLabel
              gray
              bold
              className={styles.nameLabel}
            >{`${turnData.user_first_name} ${turnData.user_last_name}`}</UTLabel>
          )}
        </div>
        <div className={styles.containerArrow}>
          <UTLabel gray>{i18.t('Turns:userInformation')}</UTLabel>
          <UTIconButton className={styles.iconButton} onClick={handleInfo}>
            <KeyboardArrowDown className={`${styles.arrowSize} ${openInfo && styles.arrowRotate}`} />
          </UTIconButton>
        </div>
      </div>
      <div className={`${openInfo ? styles.containerPhoneEmail : styles.hidden}`}>
        <UTLabel gray>{`**${i18.t('Turns:phone')}**  ${turnData.phone ||
          i18.t('Turns:notSpecified')}`}</UTLabel>
        <UTLabel gray className={styles.emailLabel}>
          {`**${i18.t('Turns:email')}** ${turnData.email || i18.t('Turns:notSpecified')}`}
        </UTLabel>
      </div>
      <UTLabel gray className={!openInfo && styles.hidden}>
        {`**${i18.t('Turns:clientNumber')}** ${turnData.client_number || i18.t('Turns:notSpecified')} `}
      </UTLabel>
    </div>
  );
};

Turn.propTypes = {
  turnData: turnType,
  showOffice: bool,
  className: string,
  afterConfirm: func,
  afterCancel: func,
  turnForTransitionId: string
};

const mapStateToProps = store => ({
  turnForTransitionId: store.office.turnForTransition.turnId
});

export default connect(mapStateToProps)(memo(Turn));
