import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { func, instanceOf, arrayOf, bool, string, shape } from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import i18 from 'i18next';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { UTLoading } from '@widergy/energy-ui';
import { isEmpty } from '@widergy/web-utils/lib/array';

import CardError from '../CardError';

import References from './components/References';
import styles from './styles.module.scss';

const Calendar = ({
  handleChange,
  currentDay,
  handleChangeMonth,
  officeWorkingDays = [],
  officeWorkingDaysLoading,
  officeWorkingDaysError,
  resetCalendar,
  cardErrorClasses,
  showRefs,
  disablePast,
  onlyDaysWithTurns
}) => {
  const shouldDisableDate = _date => {
    const currentDate = new Date().getDate();
    const currentMonth = new Date().getMonth();
    if (isEmpty(officeWorkingDays) && _date.getMonth() !== currentMonth) return !(_date.getDate() === 1);
    const day = officeWorkingDays && officeWorkingDays.find(item => item.day === _date.getDate());
    return _date.getMonth() === currentMonth && _date.getDate() === currentDate
      ? false
      : !day || !(onlyDaysWithTurns ? day.available_turns : day.available);
  };
  return (
    <div className={styles.calendarContainer}>
      <UTLoading loading={officeWorkingDaysLoading} className={styles.loading}>
        {currentDay && !officeWorkingDaysError && (
          <Fragment>
            {showRefs && <References />}
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                disableToolbar
                onMonthChange={handleChangeMonth}
                shouldDisableDate={shouldDisableDate}
                variant="static"
                value={currentDay}
                onChange={handleChange}
                disablePast={disablePast}
              />
            </MuiPickersUtilsProvider>
          </Fragment>
        )}

        {officeWorkingDaysError && (
          <CardError
            error={officeWorkingDaysError}
            buttonFunction={resetCalendar}
            textButton={i18.t('Turns:reloadCalendar')}
            classes={cardErrorClasses}
          />
        )}
      </UTLoading>
    </div>
  );
};

Calendar.propTypes = {
  handleChangeMonth: func,
  currentDay: instanceOf(Date),
  handleChange: func,
  officeWorkingDays: arrayOf(instanceOf(Date)),
  officeWorkingDaysLoading: bool,
  officeWorkingDaysError: string,
  resetCalendar: func,
  showRefs: bool,
  disablePast: bool,
  onlyDaysWithTurns: bool,
  cardErrorClasses: shape({
    cardClassName: string,
    titleClassName: string,
    labelClassName: string,
    buttonClassName: string
  })
};

const mapStateToProps = store => ({
  officeWorkingDays: store.office.officeWorkingDays,
  officeWorkingDaysLoading: store.office.officeWorkingDaysLoading,
  officeWorkingDaysError: store.office.officeWorkingDaysError
});

export default connect(mapStateToProps)(Calendar);
