import React, { memo, useState, useMemo } from 'react';
import i18 from 'i18next';
import { connect } from 'react-redux';
import { number, func, bool, string } from 'prop-types';
import { UTLabel } from '@widergy/energy-ui';
import { isEmpty } from '@widergy/web-utils/lib/array';
import Grid from '@material-ui/core/Grid';
import ScrollBar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { officesType, referenceType } from 'types/officeTypes';
import GridWrapper from 'app/screens/Dashboard/screens/Turns/components/GridWrapper';

import OfficeTag from './components/OfficeTag';
import Search from './components/Search';
import { filterOffices, getAvailableOffices } from './utils';
import styles from './styles.module.scss';

const Offices = ({
  offices,
  changeOffice,
  className,
  officesLoading,
  selectedOffice,
  title,
  titleClassName,
  gridSizeMD,
  gridSizeXS,
  reference,
  procedure
}) => {
  const [filter, setFilter] = useState('');

  const availableOfices = useMemo(() => getAvailableOffices(offices, procedure), [offices, procedure]);
  const filteredOffices = useMemo(() => filterOffices(availableOfices, filter), [availableOfices, filter]);

  return (
    <GridWrapper
      reference={reference}
      title={title}
      loading={officesLoading}
      className={className}
      titleClassName={titleClassName}
    >
      {!isEmpty(availableOfices) && <Search filter={filter} setFilter={setFilter} />}
      {isEmpty(filteredOffices) ? (
        <UTLabel bold large>
          {i18.t('Turns:searchOfficesZeroResults')}
        </UTLabel>
      ) : (
        <ScrollBar
          options={{
            suppressScrollX: true,
            wheelPropagation: false
          }}
          className={styles.scrollbar}
        >
          <Grid spacing={3} container classes={{ root: styles.gridContainer }}>
            {filteredOffices.map(office => (
              <OfficeTag
                isSelected={selectedOffice === office.id}
                office={office}
                changeOffice={changeOffice}
                gridSizeMD={gridSizeMD}
                gridSizeXS={gridSizeXS}
              />
            ))}
          </Grid>
        </ScrollBar>
      )}
    </GridWrapper>
  );
};

Offices.propTypes = {
  className: string,
  offices: officesType,
  changeOffice: func,
  selectedOffice: number,
  officesLoading: bool,
  title: string,
  titleClassName: string,
  gridSizeMD: number,
  gridSizeXS: number,
  reference: referenceType,
  procedure: string
};

const mapStateToProps = store => ({
  offices: store.office.offices,
  officesLoading: store.office.officesLoading
});

export default connect(mapStateToProps)(memo(Offices));
