import React, { useReducer, useRef, Fragment } from 'react';
import { connect } from 'react-redux';

import { officesType } from 'types/officeTypes';

import { reducer, initialState, actionsCreator } from './reducer';
import SelectProcedure from './components/SelectProcedure';
import ContactInformation from './components/ContactInformation';
import SelectOffice from './components/SelectOffice';
import SelectDate from './components/SelectDate';
import SelectTime from './components/SelectTime';
import Summary from './components/Summary';
import ResultDialog from './components/ResultDialog';
import SubmitTurn from './components/SubmitTurn';
import styles from './styles.module.scss';

const CreateTurns = ({ offices }) => {
  const [values, ownDispatch] = useReducer(reducer, initialState);
  const actions = actionsCreator(ownDispatch);
  const refs = {
    procedure: useRef(null),
    contactInformation: useRef(null),
    office: useRef(null),
    date: useRef(null),
    time: useRef(null)
  };

  const procedureOnchange = procedure => {
    if (values.selectedOffice) {
      const selectedOfficeData = offices.find(office => office.id === values.selectedOffice);
      if (selectedOfficeData && selectedOfficeData.hidden_forms?.includes(procedure)) {
        actions.handleSelectedOffice();
        actions.handleSelectedDate();
        actions.handleSelectedTime();
      }
    }
    actions.handleSelectedProcedure(procedure);
  };

  return (
    <div className={styles.wrapper}>
      <SelectProcedure
        reference={refs.procedure}
        value={values.selectedProcedure}
        onChange={procedureOnchange}
      />
      {values.selectedProcedure && (
        <ContactInformation
          reference={refs.contactInformation}
          value={values.contactInformation}
          onChange={actions.handleContactInformation}
        />
      )}
      {values.contactInformation && (
        <SelectOffice
          reference={refs.office}
          values={values}
          onChange={actions.handleSelectedOffice}
          procedure={values.selectedProcedure}
        />
      )}
      {values.selectedOffice && (
        <SelectDate reference={refs.date} values={values} onChange={actions.handleSelectedDate} />
      )}
      {values.selectedDate && (
        <SelectTime reference={refs.time} values={values} onChange={actions.handleSelectedTime} />
      )}
      {values.selectedTime && (
        <Fragment>
          <Summary refs={refs} values={values} />
          <SubmitTurn values={values} openResultDialog={actions.handleOpenResultDialog} />
        </Fragment>
      )}
      <ResultDialog isOpen={values.resultDialogOpen} handleClose={actions.handleCloseResultDialog} />
    </div>
  );
};

CreateTurns.propTypes = {
  offices: officesType
};

const mapStateToProps = store => ({
  offices: store.office.offices
});

export default connect(mapStateToProps)(CreateTurns);
