import React, { memo } from 'react';
import { func, string } from 'prop-types';
import i18 from 'i18next';
import SearchIcon from '@material-ui/icons/Search';
import { UTTextInput } from '@widergy/energy-ui';

import styles from './styles.module.scss';

const SearchOffice = ({ filter, setFilter }) => (
  <div className={styles.searchRow}>
    <SearchIcon className={styles.searchIcon} />
    <UTTextInput
      input={{ value: filter, onChange: setFilter }}
      className={styles.textInput}
      inputClassName={styles.inputClassName}
      placeholder={i18.t('Turns:searchOfficesPlaceholder')}
      meta={{}}
    />
  </div>
);

SearchOffice.propTypes = {
  filter: string,
  setFilter: func
};

export default memo(SearchOffice);
