import React, { useState } from 'react';
import { func, string } from 'prop-types';

import { referenceType } from 'types/officeTypes';

import ContactInformation from './layout';

const ContactInformationContainer = ({ reference, value, onChange }) => {
  const [disabled, setDisabled] = useState(false);

  const disableForm = () => setDisabled(true);
  const enableForm = () => setDisabled(false);

  const onSubmit = values => {
    onChange(values);
    disableForm();
  };

  return (
    <ContactInformation
      hasInitialValues={!!value}
      disabled={disabled}
      disableForm={disableForm}
      enableForm={enableForm}
      initialValues={value}
      onSubmit={onSubmit}
      reference={reference}
    />
  );
};

ContactInformationContainer.propTypes = {
  reference: referenceType,
  value: string,
  onChange: func
};

export default ContactInformationContainer;
