import React, { memo } from 'react';
import { connect } from 'react-redux';
import i18 from 'i18next';
import { createSelector } from 'reselect';
import { arrayOf, bool, string, instanceOf } from 'prop-types';
import ScrollBar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { isEmpty } from '@widergy/web-utils/lib/array';
import { UTLoading, UTLabel } from '@widergy/energy-ui';

import { turnType } from 'types/officeTypes';
import GridWrapper from 'app/screens/Dashboard/screens/Turns/components/GridWrapper';
import CardError from 'app/screens/Dashboard/screens/Turns/components/CardError';

import Turn from '../Turn';

import styles from './styles.module.scss';

const TurnsList = ({ turns, turnsLoading, calendarLoading, error, officeWorkingDays }) => (
  <UTLoading loading={turnsLoading || calendarLoading} className={styles.loading}>
    {error ? (
      <div>
        <CardError error={error} />
      </div>
    ) : (officeWorkingDays && isEmpty(officeWorkingDays)) || (turns && isEmpty(turns)) ? (
      <UTLabel darkGray large extrabold className={styles.label}>
        {i18.t('Turns:turnsByDayZeroResults')}
      </UTLabel>
    ) : (
      !isEmpty(turns) && (
        <GridWrapper>
          <div className={styles.turnsList}>
            <ScrollBar className={styles.scroll}>
              {turns.map(turn => (
                <Turn turnData={turn} />
              ))}
            </ScrollBar>
          </div>
        </GridWrapper>
      )
    )}
  </UTLoading>
);

TurnsList.propTypes = {
  turns: arrayOf(turnType),
  turnsLoading: bool,
  calendarLoading: bool,
  error: string,
  officeWorkingDays: arrayOf(instanceOf(Date))
};

export const turnsSelector = createSelector(
  store => store.office.turnsByDay,
  (_, props) => props.filterValue,
  (turnsByDay, filterValue) =>
    turnsByDay && turnsByDay.filter(item => !filterValue || item.status === filterValue)
);

const mapStateToProps = (store, props) => ({
  turns: turnsSelector(store, props),
  officeWorkingDays: store.office.officeWorkingDays,
  turnsLoading: store.office.turnsByDayLoading,
  calendarLoading: store.office.officeWorkingDaysLoading,
  error: store.office.turnsByDayError
});

export default connect(mapStateToProps)(memo(TurnsList));
