import i18n from 'i18next';

import {
  nameField,
  lastNameField,
  docTypeOptions,
  documentTypeField,
  documentNumberField,
  emailField,
  clientNumberField,
  phoneField
} from 'constants/fields';
import { stringOrDash } from 'utils/hooks/stringOrDash';

import { handleScrollTo } from '../../utils';

import { MONTHS, DAYS } from './constants';

const buildDate = date => {
  if (!date) return;
  const dayName = DAYS[date.getDay()];
  const dateNumber = date.getDate();
  const monthName = MONTHS[date.getMonth()];

  return `${dayName} ${dateNumber} de ${monthName}`;
};

const getDocumentTypeLabel = values => {
  const key = values.contactInformation[documentTypeField.key];
  const documentType = docTypeOptions.find(el => el.key === key);
  return documentType.name;
};

export const buildClientDetails = (values, refs) => [
  {
    label: `${i18n.t('Turns:summaryDetailName')} ${values.contactInformation[nameField.key]} ${
      values.contactInformation[lastNameField.key]
    }`,
    onClick: () => handleScrollTo(refs.contactInformation)
  },
  {
    label: `${getDocumentTypeLabel(values)}: ${values.contactInformation[documentNumberField.key]}`,
    onClick: () => handleScrollTo(refs.contactInformation)
  },
  {
    label: `${i18n.t('Turns:summaryDetailNis')} ${stringOrDash(
      values.contactInformation[clientNumberField.key]
    )}`,
    onClick: () => handleScrollTo(refs.contactInformation)
  },
  {
    label: `${i18n.t('Turns:summaryDetailPhone')} ${values.contactInformation[phoneField.key]}`,
    onClick: () => handleScrollTo(refs.contactInformation)
  },
  {
    label: `${i18n.t('Turns:summaryDetailEmail')}  ${stringOrDash(
      values.contactInformation[emailField.key]
    )}`,
    onClick: () => handleScrollTo(refs.contactInformation)
  }
];

export const buildTurnDetails = (values, offices, officeWorkingDaySlots, turnForms, refs) => {
  if (!values.selectedOffice || !values.selectedProcedure || !values.selectedTime) return [];

  const officeData = offices.find(el => el.id === values.selectedOffice);
  const procedureData = turnForms.find(el => el.value === values.selectedProcedure);
  const slotData = officeWorkingDaySlots.find(el => el.id === values.selectedTime);

  return [
    {
      label: `${i18n.t('Turns:summaryDetailProcedure')} ${procedureData.label}`,
      onClick: () => handleScrollTo(refs.procedure)
    },
    {
      label: `${officeData.name} ${officeData.address}`,
      onClick: () => handleScrollTo(refs.office)
    },
    {
      label: buildDate(values.selectedDate),
      onClick: () => handleScrollTo(refs.date)
    },
    {
      label: `${slotData.begins_at} - ${slotData.ends_at}`,
      onClick: () => handleScrollTo(refs.time)
    }
  ];
};
